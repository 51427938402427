<template>
    <div v-if="Show===-1" class="MLR4 MT2">
        <p>如果您要出售自己的时间或要购买别人的时间，必须遵循以下步骤规则：</p>
        <p>1.通过本平台进行交易的时间，须绑定在一个泛约项目之上。当前，您的缺省绑定项目是[{{$store.state.DefaultItem}}],
           如果想在其它项目上买卖时间，请点击 <a @click="Show=1">&nbsp;换个项目&nbsp;</a>。</p>
        <p>2.如果您要出售时间，则需要为您的项目指定可以出售的时间窗类型，请点击 <a @click="SetShow(2)">&nbsp;时间窗设置&nbsp;</a>。</p>
        <p>3.本平台规定的时间交易单位为“人次”，每人次多长时间，由出售者自行定义：</p>
        <div class="MP4 BDLightGray">
            <MyDivider ShowStr="时间商品定价标签"></MyDivider>
            <div class="XL YC PA3">
                每人次： <input class="DispIB W12" type="number" v-model.number="AppearanceFee" @blur="UpdateByset(1,AppearanceFee)"/> 个尊享
            </div>
            <div class="XL YC PA3">
                备注:<br>
                <textarea class="Mtextarea2 Box5 MR4" v-model="Text" @blur="UpdateByset(2,Text)"></textarea>
            </div>
        </div>
        <p>4.如果您要出售的时间与场地相关，可以点击我的 <a @click="Show=3">&nbsp;喜好场地&nbsp;</a> 提前进行设置。</p>
        <p>5.如果您想要买入别人的时间，请打开 <a @click="SetShow(4)">&nbsp;时间橱窗&nbsp;</a> 列表，点击相应的时间商品即可。</p>
        <br>
    </div>
    <PopBott v-if="Show===1">
        <SeleOneItem Title="缺省绑定项目选择" @FromSeleOneItem="UpdateDefaultItem"></SeleOneItem>
    </PopBott>
    <PopMenu v-if="Show===2" :Menus="['某日时段', '某年某月', '节假日']" :BeginN="0" @FromPopMenu="PopMenuVal">
        <TimeWindowSet :Msg="{ItemStr:ItemStr,bySets:bySets,}" :TypeSele="TypeSele" @eventFromTWS="Show = -1"></TimeWindowSet>
    </PopMenu>
    <PopTitle v-if="Show===3" Title="我的喜约场地" @FromPopUp="Show=-1">
        <SetMySites :ItemStr="ItemStr" @FromSetMySites="Show=-1"></SetMySites>
    </PopTitle>
    <PopTitle v-if="Show===4" Title="时间商品橱窗" @FromPopUp="Show=-1">
        <div v-if="SeleI===-1" class="ML2 XL YC">
            <div class="AlignC BGY MP4" v-for="(By,index) in bySets" :key="index" @click="SetSeleI(index,By)">
                <ShowP :Pic="By.Pic" Class="WHcc"></ShowP>
                <div class="TE T3">尊享：{{By.AppearanceFee}}个</div>
                <div class="TG">{{By.UserName}}</div>
            </div>
        </div>
    </PopTitle>
    <PopTitle v-if="SeleI!==-1" :Title="bySets[SeleI].UserName+'的时间商品柜台'" @FromPopUp="SeleI=-1,Show=4">
        <OpenUser2 :He="bySets[SeleI].UserName"></OpenUser2>
    </PopTitle>
</template>

<script>
    import PopBott from "../MyPopWin/PopBott";
    import SeleOneItem from "./SeleOneItem";
    import TimeWindowSet from "../SharedVues/TimeWindowSet";
    import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import MyDivider from "../SharedVues/MyDivider";
    import PopTitle from "../MyPopWin/PopTitle";
    import ShowP from "../MyPVTs/ShowP";
    import PopOneAN from "../MyPopWin/PopOneAN";
    import SetMySites from "../M4_Sites/SetMySites";
    import PopMenu from "../MyPopWin/PopMenu";
    import OpenUser2 from "./OpenUser2";

    export default {
        name: "TimeShop",
        components: {OpenUser2, PopMenu, SetMySites, PopOneAN, ShowP,PopTitle, MyDivider, TimeWindowSet, SeleOneItem, PopBott},
        data() {
            return {
                Show: -1,
                ItemStr: '',
                bySets: [],
                AppearanceFee: null,
                Text: '',
                SeleI: -1,
                TypeSele: 0
            }
        },
        mounted() {
            let that = this;
            this.ItemStr = this.$store.state.DefaultItem;
            myMongoDBPost("GetDocs/byset0", {UserName: this.$store.state.UserName, ItemStr: this.ItemStr}, function (Docs) {
                that.bySets = Docs;
                that.AppearanceFee = Docs[0].AppearanceFee;
                that.Text = Docs[0].Text;
            });
        },
        methods: {
            SetSeleI(index, By) {
                this.SeleI = index;
                this.Show = 0;
                if (By.UserName === this.$store.state.UserName) {
                    alert('您不能向自己发出邀约！');
                    this.Show = 4;
                    this.SeleI = -1;
                }

                //document.getElementById('Id3').scrollIntoView({behavior: 'smooth'});
            },
            UpdateDefaultItem(Val) {
                let that = this;
                this.Show = -1;
                this.ItemStr = Val;
                myMongoDBPost("AddbySetsItem", {UserName: this.$store.state.UserName, ItemStr: Val}, function () {
                });
                myMongoDBPost("UpdateDoc/fyuser0", {UPTJ: {UserName: this.$store.state.UserName}, UPObj: {DefaultItem: Val}}, function () {
                    that.$store.commit('ChangeDefaultItem', Val);
                });
            },
            UpdateByset(Bool, Val) {
                let UPObj;
                if (Bool === 1) UPObj = {AppearanceFee: Val};
                if (Bool === 2) UPObj = {Text: Val};
                myMongoDBPost("UpdateDoc/byset0", {UPTJ: {UserName: this.$store.state.UserName}, UPObj: UPObj}, function () {
                });
            },
            SetShow(Bool) {
                let that = this;
                //document.getElementById('TimeShopBegin').scrollIntoView({behavior: 'smooth'});
                if (Bool === 2) myMongoDBPost("GetDocs/byset0", {ItemStr: this.ItemStr, UserName: this.$store.state.UserName}, function (data) {
                    that.bySets = data;
                    that.Show = Bool;
                });
                if (Bool === 4) myMongoDBPost("GetDocs/byset0", {ItemStr: this.ItemStr, AppearanceFee: {$gt: 0}}, function (data) {
                    that.bySets = data;
                    that.Show = Bool;
                });
            },
            PopMenuVal(Val) {
                if (Val === -1) this.Show = -1; else this.TypeSele = Val;
            },

        }
    }
</script>

<style scoped>

</style>